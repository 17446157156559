import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";
import { ISearchSuggestionsOutput } from "lib/interfaces/search";
import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DebounceHelper from "../../../helpers/debounce-helper";
import SearchHelper from "../../../lib/helpers/search-helper";

interface SearchBarProps extends WithBundleProps {}

/**
 * The time (in ms) the debouncer will wait before
 * waiting for the next keyboard input
 */
export const DEBOUNCER_DELAY_IN_MS = 500;
// Debouncer function to fetch search suggestions
let getSearchSuggestions: (this: any, ...args: any[]) => NodeJS.Timeout;

const SearchBar = (props: SearchBarProps) => {
  const { bundle } = props;
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchSuggestionsOutput>();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const localeLanguage: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;
  const navigate = useNavigate();

  const initializeDebouncer = () => {
    getSearchSuggestions = DebounceHelper.getDebouncer(DEBOUNCER_DELAY_IN_MS, async (input) => {
      const output = await SearchHelper.getSearchSuggestions({ keywords: input?.[0], language: localeLanguage });
      setSearchSuggestions(output);
    });
  };
  const registerClickEventListener = () => window.addEventListener("click", handleClickOutside);
  const unregisterClickEventListener = () => window.removeEventListener("click", handleClickOutside);

  useEffect(() => {
    initializeDebouncer();
    registerClickEventListener();

    return () => {
      unregisterClickEventListener();
    };
  }, []);

  const handleClickOutside = () => {
    setShowSuggestions(false);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const keywords = e.target.value;
    setShowSuggestions(true);
    setSearchInput(keywords);

    if (!keywords.trim().length) {
      setShowSuggestions(false);
      setSearchSuggestions({ suggestions: [] });
      return;
    }

    getSearchSuggestions({ keywords: keywords });
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (!searchInput.trim().length) {
      return;
    }
    //e.preventDefault();
    if (e.key === "Enter") {
      // Route to the search page
      navigate(`/search?keywords=${searchInput}&language=${localeLanguage}`);
    } else if (e.key === "Escape") {
      setShowSuggestions(false);
    }
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    setShowSuggestions(true);
  };

  const validSearchSuggestions = () => {
    return searchSuggestions && searchSuggestions.suggestions && searchSuggestions.suggestions.length > 0;
  };

  return (
    <div id="search-bar">
      <input
        type="text"
        placeholder={bundle.getMessage("searchbar-placeholder")}
        value={searchInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      />
      <div id="search-suggestions" className={showSuggestions && validSearchSuggestions() ? "visible" : "invisible"}>
        <SearchSuggestions output={searchSuggestions} language={localeLanguage} />
      </div>
    </div>
  );
};

type SearchSuggestionsProps = {
  output?: ISearchSuggestionsOutput;
  language: string;
};

const SearchSuggestions = (props: SearchSuggestionsProps) => {
  return (
    <>
      {props.output?.suggestions.map((suggestion, index) => (
        <a
          key={index}
          href={window.location.origin + "/search?keywords=" + suggestion.text + "&language=" + props.language}
        >
          {suggestion.text}
        </a>
      ))}
    </>
  );
};

export default withBundle("com.a2z.it.kb.SearchBar")(SearchBar);
