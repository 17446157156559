import UrlHelper, { Stages } from "../helpers/url-helper";

type FeatureConfig = {
  [Stages.Ada]?: boolean;
  [Stages.Beta]: boolean;
  [Stages.Gamma]: boolean;
  [Stages.Prod]: boolean;
};

export const FeatureFlagByStage = (featureConfig: FeatureConfig): boolean => {
  const stage = UrlHelper.getStage() as Stages;
  return (featureConfig[stage] as boolean) ?? false;
};

export const FEATURE_FLAGS = {
  STENCIL_CONTENT: FeatureFlagByStage({
    [Stages.Ada]: true,
    [Stages.Beta]: true,
    [Stages.Gamma]: false,
    [Stages.Prod]: false,
  }),
};
